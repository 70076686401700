<template>
  <div class="appointmenthz flexcolumnstart">
    <p class="title">树兰（杭州）医院预约须知</p>
    <div class="flexcolumnstart" style="margin-bottom: 40px">
      <p>
        感谢您选择来我院就诊，为保证您顺利就诊，请您认真阅读并充分理解以下规则：
      </p>
      <p class="title3">一、新冠疫情防控要求</p>
      <p>
        1.进入医院请全程佩戴口罩，出示行程码，健康码刷码通行，配合测量体温及流行病学调查，如实填写疫情防控调查表。
      </p>
      <p>2.以下人员请至发热门诊就诊，无法在线预约挂号</p>
      <p>
        ①具有发热（体温≥37.3℃）、咽痛、流涕、干咳、乏力、嗅觉味觉减退、鼻塞、结膜炎、肌痛和腹泻等症状。
      </p>
      <p>②28天内境外旅游居住史；</p>
      <p>③21天内来自中高风险地区或14天内来自严密管控区域；</p>
      <p>④行程码、健康码显示红码或者黄码者。</p>
      <p class="title3">二、就医规则</p>
      <p>
        1.实名制就医：树兰（杭州）医院实行实名制预约挂号，请您准确提供姓名、身份证号码等，确保手机号码使用通畅，以便医生出诊时间有变动时与您联系。
      </p>
      <p>
        2.预约就诊制：所有号源提前7天开放（提前7天00：00开始预约），如周一晚上00：00开始手机端可以预约下周二的号。
      </p>
      <p>
        3.分时段就诊：请您根据预约就诊时间到院，减少排队和人员聚集，未按时者将重新排序。
      </p>
      <p class="title3">三、预约取号</p>
      <!-- <p>
      就诊当日自助机取号或手机端自费取号。如已通过手机完成支付，无须再取号，请凭手机预约记录直接到相关科室就诊。（小程序在线支付暂时关闭了的，这段建议不显示）
    </p> -->
      <p>
        就诊当日自助机取号或手机端自费取号。如已通过手机完成支付，无须再取号，请凭手机预约记录直接到相关科室就诊。
      </p>
      <p class="title3">四、取消预约、违约提醒、退号</p>
      <p>
        预约成功后，若不能按时就诊，请至少提前一天取消预约，截止时间为就诊日凌晨0点之前，过时不能取消并计入爽约，三个月内累计爽约满3次的1个月內不能预约挂号，可至医院门诊办公室核销，再次开通线上预约挂号，如未核销者1个月后再恢复预约挂号操作，但不影响现场挂号。现场取号或手机端挂号支付的挂号费均需要在就诊当日到医院收费窗口现场退费。
      </p>

      <p class="title3">五、温馨提醒</p>
      <p>
        1.就诊挂号当日有效，隔日自动作废。若诊疗需要的检查检验项目，不能当日完成，请等获取报告后再次挂号就诊。
      </p>
      <p>2.初诊且未做过相关检查的病人，建议先看普通门诊。</p>
      <p>
        3.请按预约候诊时间到院候诊，预约取号截止时间就诊日上午11：30下午16：50。
      </p>
      <p>
        4.口腔科预约仅限初诊患者，复诊患者可以诊后现场预约或请拨打0571-56131303预约，就诊当日取号后请到口腔科护士站登记分诊。
      </p>
    </div>
    <!-- <div class="bottombtn">
      <van-button
        type="primary"
        size="normal"
        color="#30B5A9"
        style="width: 80%"
        >我知道了</van-button
      >
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.appointmenthz {
  padding: 40px 30px 0;
  p {
    text-align: justify;
    font-size: 30px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #2a2c32;
    line-height: 50px;
  }
  .title {
    font-size: 36px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #2a2c32;
    line-height: 50px;
    text-align: center;
    margin-bottom: 30px;
  }
  .title3 {
    font-size: 30px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #2a2c32;
    margin: 20px 0 10px;
  }
  .bottombtn {
    background: #fff;
    // width: calc(100%-30px);
    // background: red;
    padding: 40px 0;
    position: fixed;
    bottom: 0px;
    left: 30px;
    right: 30px;
    text-align: center;
  }
}
</style>